@import url(http://fonts.googleapis.com/css?family=Roboto:400,100,300,500,700,900);
/*HELPER*/
.clear {
  clear: both; }

.left {
  float: left; }

.right {
  float: right; }

.b1 {
  border: .1rem solid darkred !important; }

.b2 {
  border: .1rem solid navy !important; }

.b3 {
  border: .1rem solid lime !important; }

.none {
  display: none; }

.tRight {
  text-align: right; }

.tCenter {
  text-align: center; }

.tLeft {
  text-align: left; }

.relative {
  position: relative; }

.hide {
  display: none !important; }

.relative {
  position: relative; }

.scrollY {
  overflow-y: auto !important;
  overflow-x: hidden !important; }

.grid {
  display: flex;
  flex-flow: row wrap;
  align-content: stretch;
  justify-content: stretch;
  align-items: flex-start;
  justify-content: space-between;
  align-content: stretch; }

/**/
/*PAGINATION*/
.pagination {
  text-align: center; }
  .pagination ul {
    display: flex;
    flex-flow: row wrap;
    align-content: stretch;
    justify-content: stretch;
    justify-content: center; }
    .pagination ul li {
      position: relative;
      margin: 0 -2px; }
      .pagination ul li a, .pagination ul li span {
        display: block;
        background: transparent;
        color: #307846;
        height: 30px;
        width: 30px;
        font-size: 1.4rem;
        line-height: 23px;
        font-weight: 700;
        cursor: pointer;
        transition: all 600ms;
        border: 4px solid #307846;
        border-radius: 100%; }
        .pagination ul li a:hover, .pagination ul li span:hover {
          background: transparent;
          color: #9BC911;
          border: 4px solid #9BC911; }
        .pagination ul li a i, .pagination ul li span i {
          margin-top: 3px; }
    .pagination ul .active {
      z-index: 99999; }
      .pagination ul .active a, .pagination ul .active span {
        color: #9BC911;
        background: transparent;
        border: 4px solid #9BC911; }
    .pagination ul .prev a, .pagination ul .next a {
      line-height: 22px !important; }

.modal {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  background: white;
  padding: 0;
  max-height: 70%;
  width: 55%;
  margin: auto;
  overflow-y: auto;
  border-radius: 2px;
  will-change: top, opacity;
  z-index: 999999; }
  @media screen and (max-width: 960px) {
    .modal {
      width: 80%; } }
  .modal h1, .modal h2, .modal h3, .modal h4 {
    margin-top: 0; }
  .modal .modal-content {
    padding: 20px; }
  .modal .modal-close {
    cursor: pointer; }
  .modal .modal-footer {
    border-radius: 0 0 2px 2px;
    background-color: #fafafa;
    padding: 4px 6px;
    height: 56px;
    width: 100%; }
    .modal .modal-footer .btn, .modal .modal-footer .btn-flat {
      float: right;
      margin: 6px 0; }

#lean-overlay {
  position: fixed;
  z-index: 99999;
  top: -100px;
  left: 0;
  bottom: 0;
  right: 0;
  height: 125%;
  width: 100%;
  background: #000;
  display: none;
  will-change: opacity;
  cursor: pointer; }

.modal.modal-fixed-footer {
  padding: 0;
  height: 70%; }
  .modal.modal-fixed-footer .modal-content {
    position: fixed;
    max-height: 100%;
    padding-bottom: 64px;
    width: 100%;
    overflow-y: auto; }
  .modal.modal-fixed-footer .modal-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 0; }

.modal.bottom-sheet {
  top: auto;
  bottom: -100%;
  margin: 0;
  width: 100%;
  max-height: auto;
  border-radius: 0;
  will-change: bottom, opacity; }

header {
  border-top: 2px solid #9BC911;
  box-shadow: 0px 3px 4px rgba(102, 102, 102, 0.6);
  background: white;
  position: relative;
  z-index: 99; }
  header .row {
    margin-bottom: 0; }
  header .logo {
    margin: 10px 0;
    display: block;
    width: 100%;
    height: 80px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }

header.fixed {
  box-shadow: 0px 3px 4px rgba(102, 102, 102, 0.6);
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 99; }
  header.fixed .row {
    margin-bottom: 0; }
    header.fixed .row .col {
      padding: 0; }

header.noFixed {
  position: relative;
  width: 100%; }
  header.noFixed nav {
    margin-top: 40px; }
  header.noFixed .logo {
    height: 80px;
    margin: 10px 0; }
  header.noFixed li a {
    color: #9BC911;
    transition: all 0.2s; }
    header.noFixed li a:hover {
      color: #307846; }

.langs {
  top: 0px;
  right: 0px;
  position: absolute;
  font-size: 0;
  background: #9BC911;
  padding: 5px; }
  .langs span, .langs a {
    font-size: 1.3rem;
    line-height: 100%;
    font-weight: 400;
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    vertical-align: middle; }
  .langs span {
    color: #f6f6f6;
    margin: 0 5px; }
  .langs a {
    color: #f6f6f6; }
    .langs a:hover {
      color: #fcfcfc; }
  .langs .active_idiomas {
    color: #fcfcfc; }

header .social {
  text-align: center;
  top: 170px;
  right: 10px;
  position: absolute;
  position: fixed;
  margin: 20px 0; }
  header .social a {
    display: block;
    text-align: center;
    height: 34px;
    width: 34px;
    border-radius: 100%;
    margin: 0 0 5px;
    background: #9BC911;
    transition: all 0.2s; }
    header .social a:hover i {
      color: #307846; }
    header .social a i {
      margin-top: 8px;
      color: #f6f6f6;
      font-size: 2rem;
      line-height: 100%;
      font-weight: normal;
      transition: all 0.2s; }

footer.fixed {
  position: absolute;
  bottom: 0;
  left: 0; }

footer {
  text-align: center;
  padding-bottom: 10px;
  width: 100%;
  z-index: 99999999;
  color: white;
  background-image: url(../img/patterns/9.png);
  background-color: #9BC911;
  font-size: 1.4rem;
  line-height: 100%;
  font-weight: 400; }
  footer img {
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    height: 60px;
    opacity: 0.5;
    transition: all 600ms;
    margin: 0px 0 0; }
    footer img:hover {
      opacity: 1; }
  footer .social {
    text-align: center;
    margin: 20px 0; }
    footer .social a {
      display: inline-block;
      vertical-align: top;
      white-space: normal;
      text-align: center;
      height: 30px;
      width: 30px;
      border-radius: 5px;
      margin: 0 0 0 2px;
      background: #f6f6f6;
      transition: all 600ms; }
      footer .social a:hover {
        background: #0372AA; }
        footer .social a:hover i {
          color: #f6f6f6; }
      footer .social a i {
        margin-top: 5px;
        color: #0372AA;
        font-size: 2rem;
        line-height: 100%;
        font-weight: normal;
        transition: all 600ms; }
  footer .text {
    color: white;
    font-size: 1.4rem;
    line-height: 150%;
    font-weight: 300; }
  footer .capa {
    background: #91bf07;
    padding: 10px 0; }
    footer .capa .row {
      margin-bottom: 0; }
  footer a {
    color: #9BC911; }
  footer .row {
    margin-bottom: 0; }
  footer figure {
    margin: 20px 0;
    width: 100%;
    text-align: center; }
    footer figure img {
      height: 80px;
      opacity: 0.6; }
  footer nav {
    margin: 45px 0 0;
    border-left: thin solid #f6f6f6; }
    footer nav ul {
      width: 100%;
      text-align: center; }
      footer nav ul li {
        display: block;
        margin: 0 0px; }
        footer nav ul li a {
          padding: 10px 0px 10px 20px;
          text-align: left;
          text-transform: uppercase;
          display: block;
          transition: all 600ms;
          color: white; }
          footer nav ul li a:hover {
            color: #448c5a; }
      footer nav ul .last {
        border: none !important; }
  footer .contact-info {
    width: 100%;
    border-top: thin solid #dedede;
    margin: 0px 0 10px;
    padding: 20px 0 0;
    text-align: center; }
    footer .contact-info > div {
      display: inline-block;
      vertical-align: top;
      white-space: normal;
      margin: 0 5px; }
      footer .contact-info > div i {
        margin-right: 5px; }
      footer .contact-info > div a {
        color: white;
        text-decoration: underline; }
        footer .contact-info > div a:hover {
          color: #448c5a; }
  footer .copyright {
    margin-top: 5px;
    display: block; }
  footer .ahorranito {
    text-align: right;
    margin-top: 3px; }
    footer .ahorranito a {
      display: inline-block;
      vertical-align: top;
      white-space: normal;
      color: white;
      vertical-align: middle; }
    footer .ahorranito .ahorranito-icon {
      display: inline-block;
      vertical-align: top;
      white-space: normal;
      vertical-align: middle; }

@media screen and (max-width: 960px) {
  footer .ahorranito, footer .copyright {
    margin-bottom: 10px;
    text-align: center;
    line-height: 120%; } }

@media screen and (max-width: 601px) {
  footer nav ul li a, footer .contact-info > div {
    line-height: 150%; } }

.ahorranito span {
  color: #1d9ad4; }
.ahorranito .ahorranito-icon {
  background-image: url(../img/ahorranito.png);
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  height: 20px;
  vertical-align: middle;
  width: 20px; }

