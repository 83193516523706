@import url(http://fonts.googleapis.com/css?family=Roboto:400,100,300,500,700,900);
/*HELPER*/
.clear {
  clear: both; }

.left {
  float: left; }

.right {
  float: right; }

.b1 {
  border: .1rem solid darkred !important; }

.b2 {
  border: .1rem solid navy !important; }

.b3 {
  border: .1rem solid lime !important; }

.none {
  display: none; }

.tRight {
  text-align: right; }

.tCenter {
  text-align: center; }

.tLeft {
  text-align: left; }

.relative {
  position: relative; }

.hide {
  display: none !important; }

.relative {
  position: relative; }

.scrollY {
  overflow-y: auto !important;
  overflow-x: hidden !important; }

.grid {
  display: flex;
  flex-flow: row wrap;
  align-content: stretch;
  justify-content: stretch;
  align-items: flex-start;
  justify-content: space-between;
  align-content: stretch; }

/**/
/*PAGINATION*/
.pagination {
  text-align: center; }
  .pagination ul {
    display: flex;
    flex-flow: row wrap;
    align-content: stretch;
    justify-content: stretch;
    justify-content: center; }
    .pagination ul li {
      position: relative;
      margin: 0 -2px; }
      .pagination ul li a, .pagination ul li span {
        display: block;
        background: transparent;
        color: #307846;
        height: 30px;
        width: 30px;
        font-size: 1.4rem;
        line-height: 23px;
        font-weight: 700;
        cursor: pointer;
        transition: all 600ms;
        border: 4px solid #307846;
        border-radius: 100%; }
        .pagination ul li a:hover, .pagination ul li span:hover {
          background: transparent;
          color: #9BC911;
          border: 4px solid #9BC911; }
        .pagination ul li a i, .pagination ul li span i {
          margin-top: 3px; }
    .pagination ul .active {
      z-index: 99999; }
      .pagination ul .active a, .pagination ul .active span {
        color: #9BC911;
        background: transparent;
        border: 4px solid #9BC911; }
    .pagination ul .prev a, .pagination ul .next a {
      line-height: 22px !important; }

.modal {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  background: white;
  padding: 0;
  max-height: 70%;
  width: 55%;
  margin: auto;
  overflow-y: auto;
  border-radius: 2px;
  will-change: top, opacity;
  z-index: 999999; }
  @media screen and (max-width: 960px) {
    .modal {
      width: 80%; } }
  .modal h1, .modal h2, .modal h3, .modal h4 {
    margin-top: 0; }
  .modal .modal-content {
    padding: 20px; }
  .modal .modal-close {
    cursor: pointer; }
  .modal .modal-footer {
    border-radius: 0 0 2px 2px;
    background-color: #fafafa;
    padding: 4px 6px;
    height: 56px;
    width: 100%; }
    .modal .modal-footer .btn, .modal .modal-footer .btnSec, .modal .modal-footer .btn-flat {
      float: right;
      margin: 6px 0; }

#lean-overlay {
  position: fixed;
  z-index: 99999;
  top: -100px;
  left: 0;
  bottom: 0;
  right: 0;
  height: 125%;
  width: 100%;
  background: #000;
  display: none;
  will-change: opacity;
  cursor: pointer; }

.modal.modal-fixed-footer {
  padding: 0;
  height: 70%; }
  .modal.modal-fixed-footer .modal-content {
    position: fixed;
    max-height: 100%;
    padding-bottom: 64px;
    width: 100%;
    overflow-y: auto; }
  .modal.modal-fixed-footer .modal-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 0; }

.modal.bottom-sheet {
  top: auto;
  bottom: -100%;
  margin: 0;
  width: 100%;
  max-height: auto;
  border-radius: 0;
  will-change: bottom, opacity; }

*, *:before, *:after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.btn, .btnSec, .btn-large {
  background: #307846;
  color: #f6f6f6; }
  .btn:hover, .btnSec:hover, .btn-large:hover {
    background: #448c5a;
    color: #f6f6f6; }

.btnSec {
  background: #307846;
  color: #f6f6f6; }
  .btnSec:hover {
    background: #448c5a;
    color: #f6f6f6; }
  .btnSec:focus {
    background: #58a06e;
    color: #f6f6f6; }

select, input[type="text"], input[type="password"], textarea {
  font-size: 1.3rem !important;
  color: #666 !important;
  font-family: 'Roboto', sans-serif;
  font-weight: 400; }

.text ul {
  margin-top: 10px; }
  .text ul li {
    padding: 0 0 0 16px;
    position: relative;
    font-weight: 300;
    margin-bottom: 10px; }
    .text ul li::before {
      font-family: fontAwesome;
      color: #307846;
      font-size: 1rem;
      line-height: 100%;
      font-weight: normal;
      top: 6px;
      left: 0;
      position: absolute;
      content: '\f1ce';
      transform: rotate(90deg); }

main a {
  color: #9BC911;
  transition: all 0.2s;
  text-decoration: none;
  font-size: 1.2rem;
  line-height: 100%;
  font-weight: 400; }
  main a:hover {
    color: #307846; }

ul, ol {
  margin: 0; }

p {
  font-size: 1.4rem;
  line-height: 150%;
  font-weight: 400;
  color: #666; }

.wrapper {
  position: relative;
  width: 70%;
  margin: 0 auto 0; }
  @media screen and (max-width: 960px) {
    .wrapper {
      width: 90%; } }

html {
  font-size: 62.5%; }

body, hmtl {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 100%;
  font-weight: 400;
  color: #666; }

*:focus {
  outline: none;
  border: none;
  box-shadow: none; }

p {
  margin-bottom: 0px;
  line-height: 130%; }
  p:nth-last-child {
    margin-bottom: 0; }

.btn-link {
  color: #161616;
  font-size: 1.4rem;
  line-height: 100%;
  font-weight: 500;
  transition: all 600ms;
  margin-top: 10px; }
  .btn-link:hover {
    text-decoration: none;
    color: #9BC911; }

.text {
  font-size: 1.4rem;
  line-height: 150%;
  font-weight: 400;
  color: #666; }

nav ul {
  list-style-image: none !important;
  list-style-type: none !important;
  list-style: none !important; }

.bx-wrapper {
  margin-bottom: 0; }

.bx-viewport {
  border: none !important;
  box-shadow: none; }

.btn.return, .return.btnSec {
  float: right;
  margin-bottom: 20px; }

.alert, .alert-error, .alert-success {
  position: relative;
  width: 70%;
  margin: 0 auto 0;
  font-size: 1.3rem;
  line-height: 130%;
  font-weight: 300;
  color: #f6f6f6;
  padding: 10px;
  background: white;
  border-radius: 4px;
  margin: 10px 0;
  border: thin solid #fcfcfc; }
  @media screen and (max-width: 960px) {
    .alert, .alert-error, .alert-success {
      width: 90%; } }
  .alert p, .alert-error p, .alert-success p {
    color: inherit;
    font-size: inheritrem;
    line-height: inherit;
    font-weight: inherit;
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    font-size: inherit;
    vertical-align: middle; }
  .alert i, .alert-error i, .alert-success i {
    margin-right: 10px;
    font-size: 3rem;
    line-height: 100%;
    font-weight: normal;
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    vertical-align: middle; }

.alert-error {
  background: #FF9494;
  border: 2px solid #f58a8a; }
  .alert-error i {
    color: #e17676; }

.alert-success {
  background: #5fe35f;
  border: 2px solid #55d955; }
  .alert-success i {
    color: #41c541; }

img {
  max-width: 100%;
  height: auto; }

section.social {
  top: 220px;
  right: 10px;
  position: absolute;
  position: fixed;
  z-index: 9999999; }
  section.social a {
    height: 30px;
    width: 30px;
    border-radius: 100%;
    background: #307846;
    text-align: center;
    margin-bottom: 5px;
    display: block;
    border: 2px solid #fcfcfc;
    transition: all 600ms; }
    section.social a:hover {
      background: #9BC911;
      border: 2px solid #9BC911; }
    section.social a i {
      color: #f6f6f6;
      font-size: 1.6rem;
      line-height: 100%;
      font-weight: normal;
      display: inline-block;
      vertical-align: top;
      white-space: normal;
      vertical-align: middle;
      margin-top: 5px; }

.return {
  margin-bottom: 20px; }

.panel {
  margin: 0px 0 20px;
  background: #f2f2f2;
  padding: 20px 0;
  font-size: 1.6rem;
  line-height: 140%;
  font-weight: 300;
  display: block; }

main.error-page {
  text-align: center; }
  main.error-page h1 {
    color: #f6f6f6;
    font-size: 4rem;
    line-height: 150%;
    font-weight: 700;
    margin: 20px 0;
    text-transform: uppercase; }
  main.error-page h2, main.error-page a {
    font-size: 3rem;
    line-height: 150%;
    font-weight: 700;
    color: #161616; }
  main.error-page a {
    text-decoration: underline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

.date, .autor {
  color: #666;
  width: 100%;
  font-size: 1.3rem;
  line-height: 100%;
  font-weight: 300;
  margin: 10px 0; }
  .date i, .autor i {
    color: #666;
    margin-right: 5px; }

a {
  transition: all 0.2s; }

main {
  background: white; }

.btn, .btnSec, .btn-flat, .btn-large, .btnSec, .card a, .myServices .highlight h4, .picker__month-display, .tabs li.tab, main h1, main.error-page h1 {
  text-transform: capitalize; }

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
  background: transparent !important; }

main h1 {
  margin: 0;
  background: #9BC911;
  color: #f6f6f6;
  margin: 0 0 0px;
  font-size: 3rem;
  line-height: 100%;
  font-weight: 700;
  text-transform: uppercase;
  padding: 20px 10px;
  background-image: url(../img/patterns/9.png);
  margin-bottom: 0px; }
  main h1 .icon {
    top: 0;
    right: 0;
    position: absolute; }
    main h1 .icon img {
      height: 40px;
      margin-right: 20px;
      display: inline-block;
      vertical-align: top;
      white-space: normal;
      display: none;
      vertical-align: middle; }
main h2 {
  color: #9BC911;
  margin: 20px 0 0; }

