@import url(http://fonts.googleapis.com/css?family=Roboto:400,100,300,500,700,900);
/*HELPER*/
.clear {
  clear: both; }

.left {
  float: left; }

.right {
  float: right; }

.b1 {
  border: .1rem solid darkred !important; }

.b2 {
  border: .1rem solid navy !important; }

.b3 {
  border: .1rem solid lime !important; }

.none {
  display: none; }

.tRight {
  text-align: right; }

.tCenter {
  text-align: center; }

.tLeft {
  text-align: left; }

.relative {
  position: relative; }

.hide {
  display: none !important; }

.relative {
  position: relative; }

.scrollY {
  overflow-y: auto !important;
  overflow-x: hidden !important; }

.grid {
  display: flex;
  flex-flow: row wrap;
  align-content: stretch;
  justify-content: stretch;
  align-items: flex-start;
  justify-content: space-between;
  align-content: stretch; }

/**/
/*PAGINATION*/
.pagination {
  text-align: center; }
  .pagination ul {
    display: flex;
    flex-flow: row wrap;
    align-content: stretch;
    justify-content: stretch;
    justify-content: center; }
    .pagination ul li {
      position: relative;
      margin: 0 -2px; }
      .pagination ul li a, .pagination ul li span {
        display: block;
        background: transparent;
        color: #307846;
        height: 30px;
        width: 30px;
        font-size: 1.4rem;
        line-height: 23px;
        font-weight: 700;
        cursor: pointer;
        transition: all 600ms;
        border: 4px solid #307846;
        border-radius: 100%; }
        .pagination ul li a:hover, .pagination ul li span:hover {
          background: transparent;
          color: #9BC911;
          border: 4px solid #9BC911; }
        .pagination ul li a i, .pagination ul li span i {
          margin-top: 3px; }
    .pagination ul .active {
      z-index: 99999; }
      .pagination ul .active a, .pagination ul .active span {
        color: #9BC911;
        background: transparent;
        border: 4px solid #9BC911; }
    .pagination ul .prev a, .pagination ul .next a {
      line-height: 22px !important; }

.modal {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  background: white;
  padding: 0;
  max-height: 70%;
  width: 55%;
  margin: auto;
  overflow-y: auto;
  border-radius: 2px;
  will-change: top, opacity;
  z-index: 999999; }
  @media screen and (max-width: 960px) {
    .modal {
      width: 80%; } }
  .modal h1, .modal h2, .modal h3, .modal h4 {
    margin-top: 0; }
  .modal .modal-content {
    padding: 20px; }
  .modal .modal-close {
    cursor: pointer; }
  .modal .modal-footer {
    border-radius: 0 0 2px 2px;
    background-color: #fafafa;
    padding: 4px 6px;
    height: 56px;
    width: 100%; }
    .modal .modal-footer .btn, .modal .modal-footer .btn-flat {
      float: right;
      margin: 6px 0; }

#lean-overlay {
  position: fixed;
  z-index: 99999;
  top: -100px;
  left: 0;
  bottom: 0;
  right: 0;
  height: 125%;
  width: 100%;
  background: #000;
  display: none;
  will-change: opacity;
  cursor: pointer; }

.modal.modal-fixed-footer {
  padding: 0;
  height: 70%; }
  .modal.modal-fixed-footer .modal-content {
    position: fixed;
    max-height: 100%;
    padding-bottom: 64px;
    width: 100%;
    overflow-y: auto; }
  .modal.modal-fixed-footer .modal-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 0; }

.modal.bottom-sheet {
  top: auto;
  bottom: -100%;
  margin: 0;
  width: 100%;
  max-height: auto;
  border-radius: 0;
  will-change: bottom, opacity; }

.dropdown-content li > a {
  font-weight: 400 !important;
  font-size: 1.4rem !important;
  color: #666 !important; }
  .dropdown-content li > a:hover {
    color: #9BC911 !important; }
  .dropdown-content li > a i {
    margin-right: 10px; }

.dropdown-content li > a {
  color: #9BC911 !important; }
  .dropdown-content li > a:hover {
    color: #307846 !important; }

.dropdown-content {
  width: auto !important;
  overflow: hidden !important; }

header nav {
  margin-top: 44px; }
  header nav ul {
    text-align: center;
    font-size: 0;
    text-align: right; }
  header nav li {
    text-align: center;
    margin: 0 0 0 40px;
    overflow: hidden;
    position: relative;
    display: inline-block;
    vertical-align: top;
    white-space: normal; }
    header nav li i {
      margin-left: 2px; }
    header nav li a {
      font-size: 1.7rem;
      line-height: 100%;
      font-weight: 500;
      text-transform: uppercase;
      padding: 0 0px;
      color: #9BC911;
      display: block;
      transition: all 600ms;
      text-decoration: none;
      position: relative; }
      header nav li a:hover {
        color: #307846; }
    header nav li .subnav, header nav li .dropdown {
      background: #f6f6f6;
      position: relative; }
      header nav li .subnav li, header nav li .dropdown li {
        display: block;
        border-bottom: thin solid #fcfcfc; }
        header nav li .subnav li a, header nav li .dropdown li a {
          text-align: center;
          font-size: 1.3rem;
          line-height: 100%;
          font-weight: 400; }
  header nav .icon {
    background: #9BC911;
    text-align: center;
    width: 40px;
    padding: 0 !important;
    height: 35px; }
    header nav .icon a {
      text-align: center;
      display: block;
      height: 35px;
      padding: 0 !important; }
      header nav .icon a:hover {
        background: #307846; }
      header nav .icon a i {
        margin: 0;
        font-size: 2rem;
        line-height: 100%;
        font-weight: normal;
        margin-top: 7px;
        transition: all 0.2s; }
  header nav .current a {
    color: #307846;
    text-decoration: none; }
    header nav .current a::before {
      height: 3px;
      width: 100%;
      background: #307846; }

.button-collapse {
  top: 10px;
  left: 10px;
  position: absolute;
  z-index: 996;
  transition: all 600ms;
  cursor: pointer; }
  .button-collapse i {
    font-size: 3rem;
    line-height: 100%;
    font-weight: normal; }

#sidenav-overlay, .drag-target {
  cursor: pointer; }

@media screen and (max-width: 960px) {
  .container > .logo {
    width: 100%;
    max-width: 100%;
    text-align: center; } }

.myMenu .logo {
  display: none; }

.side-nav li {
  border-bottom: thin solid #fcfcfc; }
  .side-nav li a {
    color: #9BC911;
    display: block;
    padding: 10px;
    font-size: 1.6rem;
    line-height: 100%;
    font-weight: 400;
    text-transform: capitalize; }

.fixed-menu {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  position: fixed; }

.button-collapse i {
  color: #9BC911; }

section.banner {
  width: 100%;
  position: relative;
  height: 460px; }
  section.banner .bgvideo {
    position: relative;
    z-index: 1;
    height: 460px;
    overflow: hidden; }
    section.banner .bgvideo video {
      height: auto !important;
      width: 100% !important; }

.slider {
  height: 460px;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 2;
  width: 100%; }
  .slider .slides {
    height: 460px; }
  .slider li > a:before {
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
    content: ''; }
  .slider h2 {
    color: #f6f6f6;
    font-size: 5rem;
    line-height: 120%;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 30px;
    width: 100%;
    margin-top: 50px; }
  .slider p {
    color: #f6f6f6;
    font-size: 3rem;
    line-height: 150%;
    font-weight: 400;
    width: 100%;
    margin-bottom: 30px; }
  .slider .btn {
    text-transform: uppercase;
    margin: 0 5px 10px; }
  .slider .btn-link {
    display: block;
    text-transform: uppercase;
    color: white;
    font-size: 1.6rem;
    line-height: 150%;
    font-weight: 300; }
    .slider .btn-link:hover {
      color: #307846; }
  .slider .caption {
    text-align: center;
    top: 0px;
    height: 100%;
    z-index: 99999; }
    .slider .caption .caption-container {
      max-width: 70%;
      margin: 0 auto; }
    .slider .caption .caption-container.left {
      text-align: left;
      float: left; }
    .slider .caption .caption-container.right {
      text-align: right;
      float: right; }
  .slider .indicators {
    bottom: 10px;
    text-align: center;
    z-index: 99; }
    .slider .indicators li.indicator-item {
      border: 4px solid #f6f6f6;
      width: 20px;
      height: 20px;
      margin: 0 3px;
      background: transparent;
      border-radius: 100%;
      transition: all 600ms; }
      .slider .indicators li.indicator-item.active {
        background: transparent;
        border: 4px solid #307846; }

@media screen and (max-width: 601px) {
  .slider h2 {
    font-size: 2.4rem;
    line-height: 120%;
    font-weight: 700; }
  .slider p {
    font-size: 1.6rem;
    line-height: 140%;
    font-weight: 400; } }
@media screen and (max-width: 960px) {
  .slider img {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; } }

section.info {
  position: relative;
  background: #f2f2f2;
  background-image: url(../img/patterns/9.png);
  text-align: center;
  margin: 0;
  padding: 15px 0 30px; }
  section.info h1 {
    font-size: 3.2rem;
    line-height: 100%;
    font-weight: 400;
    color: #9BC911;
    margin: 10px 0; }
  section.info .text {
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    color: #666;
    margin: 0px;
    font-size: 2rem;
    line-height: 150%;
    font-weight: 300;
    width: 100%; }
  section.info img {
    height: 40px;
    margin-top: 20px; }

section.outstanding {
  text-align: center;
  background: #fcfcfc;
  background-image: url(../img/patterns/0.png);
  position: relative;
  text-align: center;
  margin: 0;
  padding: 25px 0 10px; }
  section.outstanding h2 {
    color: #9BC911;
    margin-bottom: 10px;
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    width: auto;
    border-bottom: thin solid #f6f6f6;
    text-align: center;
    text-transform: uppercase;
    padding: 0 60px 5px;
    font-size: 2.6rem;
    line-height: 100%;
    font-weight: 300; }
  section.outstanding a {
    display: block; }
  section.outstanding article {
    text-align: center;
    display: block;
    background: white;
    transition: all 600ms;
    height: 380px;
    padding-bottom: 20px; }
    section.outstanding article:hover h3 {
      background: #307846; }
    section.outstanding article figure {
      height: 200px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat; }
    section.outstanding article h3 {
      text-align: center;
      padding: 10px;
      transition: all 0.4s;
      font-size: 1.8rem;
      line-height: 130%;
      font-weight: 400;
      color: #f6f6f6;
      background: #9BC911;
      display: block;
      position: relative;
      text-transform: uppercase;
      margin: 0 0 20px; }
    section.outstanding article p {
      transition: all 600ms;
      padding: 0 20px;
      text-align: left;
      color: #161616;
      font-size: 1.5rem;
      line-height: 140%;
      font-weight: 300; }
    section.outstanding article .btn {
      display: inline-block;
      vertical-align: top;
      white-space: normal;
      margin-top: 10px;
      text-transform: uppercase;
      font-size: 1.4rem;
      line-height: 36px;
      font-weight: 300;
      margin-right: 20px;
      color: #f6f6f6; }
      section.outstanding article .btn i {
        margin-left: 10px; }

section.highlight {
  text-align: center;
  background: white;
  background-image: url(../img/patterns/0.png);
  position: relative;
  text-align: center;
  margin: 0;
  padding: 25px 0 10px; }
  section.highlight .weDo {
    text-align: left; }
    section.highlight .weDo h2 {
      color: #9BC911;
      margin-bottom: 20px;
      display: inline-block;
      vertical-align: top;
      white-space: normal;
      width: 100%;
      border-bottom: thin solid #9BC911;
      text-align: left;
      text-transform: uppercase;
      padding: 0 10px 5px 10px;
      font-size: 2.6rem;
      line-height: 100%;
      font-weight: 300; }
    section.highlight .weDo .text {
      font-size: 1.6rem;
      line-height: 150%;
      font-weight: 300;
      padding: 0 10px; }
    section.highlight .weDo .icon {
      display: block;
      margin: 20px 0 0;
      text-align: center;
      position: relative; }
      section.highlight .weDo .icon i {
        font-size: 2.4rem;
        line-height: 100%;
        font-weight: normal;
        color: #dedede; }
      section.highlight .weDo .icon::before {
        content: '';
        height: 1px;
        width: 230px;
        background: #dedede;
        top: 10px;
        left: 10px;
        position: absolute; }
      section.highlight .weDo .icon::after {
        content: '';
        height: 1px;
        width: 230px;
        background: #dedede;
        top: 10px;
        right: 10px;
        position: absolute; }
    section.highlight .weDo h3 {
      text-align: center;
      padding: 0 10px 0 0;
      font-size: 2rem;
      line-height: 130%;
      font-weight: 700;
      color: #161616;
      display: block;
      position: relative;
      text-transform: uppercase;
      margin: 15px 20px 0; }
    section.highlight .weDo p {
      transition: all 600ms;
      padding: 0 20px;
      text-align: left;
      color: #161616;
      font-size: 1.6rem;
      line-height: 160%;
      font-weight: 300; }
  section.highlight .hireUs {
    background: #9BC911;
    height: 300px;
    padding: 20px;
    position: relative;
    overflow: hidden;
    border-bottom: 4px solid #307846; }
    section.highlight .hireUs h2 {
      color: #fcfcfc;
      font-size: 2.6rem;
      line-height: 100%;
      font-weight: 300;
      margin-bottom: 15px;
      text-transform: uppercase;
      position: relative; }
    section.highlight .hireUs .text {
      color: #f6f6f6;
      font-size: 1.7rem;
      line-height: 150%;
      font-weight: 100;
      text-align: justify; }
    section.highlight .hireUs a {
      position: absolute;
      bottom: 20px;
      left: 50%;
      margin-left: -100px; }
    section.highlight .hireUs .icon {
      opacity: 0.1;
      height: 120px;
      width: 182px;
      top: 50%;
      left: 50%;
      position: absolute;
      margin: -60px 0 0 -91px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat; }
      section.highlight .hireUs .icon i {
        font-size: 20rem;
        line-height: 100%;
        font-weight: normal; }

section.carousel {
  background-color: #307846;
  background-image: url(../img/patterns/0.png);
  padding: 30px 0 30px;
  margin: 0;
  width: 100%;
  position: relative;
  text-align: center; }
  section.carousel img {
    height: 100px;
    width: 100%;
    transition: all 0.4s;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%); }
    section.carousel img:hover {
      filter: grayscale(0);
      -webkit-filter: grayscale(0); }
  section.carousel i {
    font-size: 12rem;
    line-height: 100%;
    font-weight: normal;
    color: #f6f6f6;
    opacity: 0.4; }
  section.carousel h2 {
    color: #f6f6f6;
    font-size: 2.6rem;
    line-height: 100%;
    font-weight: 400;
    margin: 0px 0 20px;
    text-transform: uppercase; }
  section.carousel h3 {
    margin: 10px 0 0;
    font-size: 1.6rem;
    line-height: 100%;
    font-weight: 400;
    text-align: right;
    color: #f6f6f6; }
  section.carousel .text {
    font-size: 1.5rem;
    line-height: 140%;
    font-weight: 300;
    color: #f6f6f6;
    border-bottom: thin solid #f6f6f6;
    text-align: left;
    padding-bottom: 10px; }
  section.carousel .bx-wrapper .bx-pager, section.carousel .bx-wrapper .bx-controls-auto {
    bottom: -40px !important; }
  section.carousel .bx-wrapper .bx-pager.bx-default-pager a {
    background: transparent !important;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    margin: 0 2px !important;
    border: 4px solid #f6f6f6;
    transition: all 600ms; }
  section.carousel .bx-wrapper .bx-pager.bx-default-pager a.active {
    background: transparent !important;
    border: 4px solid #9BC911; }
  section.carousel .container-responsive {
    width: 100%;
    margin: 0 auto 0; }

section.carousel1 {
  background-color: #fcfcfc;
  background-image: url(../img/patterns/5.png);
  padding: 30px 0 30px;
  margin: 0;
  width: 100%;
  position: relative;
  text-align: center; }
  section.carousel1 img {
    height: 100px;
    width: 100%;
    transition: all 0.4s;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%); }
    section.carousel1 img:hover {
      filter: grayscale(0);
      -webkit-filter: grayscale(0); }
  section.carousel1 i {
    font-size: 12rem;
    line-height: 100%;
    font-weight: normal;
    color: #f6f6f6;
    opacity: 0.4; }
  section.carousel1 h2 {
    color: #9BC911;
    font-size: 2.6rem;
    line-height: 100%;
    font-weight: 400;
    margin: 0px 0 20px;
    text-transform: uppercase; }
  section.carousel1 h3 {
    margin: 10px 0 0;
    font-size: 1.6rem;
    line-height: 100%;
    font-weight: 400;
    text-align: right;
    color: #f6f6f6; }
  section.carousel1 .text {
    font-size: 1.5rem;
    line-height: 140%;
    font-weight: 300;
    color: #f6f6f6;
    border-bottom: thin solid #f6f6f6;
    text-align: left;
    padding-bottom: 10px; }
  section.carousel1 .bx-wrapper .bx-pager, section.carousel1 .bx-wrapper .bx-controls-auto {
    bottom: -40px !important; }
  section.carousel1 .bx-wrapper .bx-pager.bx-default-pager a {
    background: transparent !important;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    margin: 0 2px !important;
    border: 3px solid #307846;
    transition: all 600ms; }
  section.carousel1 .bx-wrapper .bx-pager.bx-default-pager a.active {
    background: transparent !important;
    border: 3px solid #666; }
  section.carousel1 .container-responsive {
    width: 100%;
    margin: 0 auto 0; }

main.aboutUs figure {
  margin: 0 0 20px;
  height: 300px;
  width: 100%;
  border: 4px solid #fcfcfc;
  overflow: hidden;
  background: white;
  text-align: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
main.aboutUs .row {
  margin-bottom: 0; }
main.aboutUs .tabs {
  height: 30px !important;
  background: transparent !important;
  margin-bottom: 20px; }
  main.aboutUs .tabs li {
    height: auto !important;
    background: transparent !important; }
    main.aboutUs .tabs li a {
      font-size: 1.6rem;
      line-height: 100%;
      font-weight: 600;
      text-transform: uppercase;
      color: #307846 !important; }
    main.aboutUs .tabs li .active {
      color: #9BC911 !important; }
main.aboutUs .tabs .indicator {
  background: #9BC911 !important; }
main.aboutUs .tab-content {
  margin-bottom: 20px; }
  main.aboutUs .tab-content h2 {
    font-size: 2rem;
    line-height: 100%;
    font-weight: 700;
    margin-bottom: 20px; }

.workTeam .text {
  margin-bottom: 20px; }
.workTeam article {
  border-bottom: thin solid #fcfcfc;
  margin: 5px 0;
  border-bottom: thin solid #e8e8e8;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative; }
  .workTeam article h2 {
    font-size: 2rem;
    line-height: 100%;
    font-weight: 400;
    color: #9BC911;
    margin: 0 0 20px;
    text-transform: uppercase; }
  .workTeam article .position {
    padding: 5px 0;
    color: #9BC911;
    margin-bottom: 10px;
    margin-right: 20px;
    border-top: thin dotted #e8e8e8;
    border-bottom: thin dotted #e8e8e8; }
    .workTeam article .position i {
      margin-right: 5px; }
  .workTeam article .mail {
    display: block;
    border-top: thin dotted #e8e8e8;
    padding: 5px 0 8px;
    margin-right: 20px; }
    .workTeam article .mail i {
      margin-right: 5px; }
  .workTeam article figure {
    float: right;
    width: 100%;
    height: 240px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border: 6px solid #e8e8e8; }
  .workTeam article .icon {
    position: absolute;
    bottom: -20px;
    left: 50%;
    margin: 0 0 0 -30px;
    height: 40px;
    width: 60px;
    background: white; }
    .workTeam article .icon span {
      background-image: url(../img/logotipo-gray.png);
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      opacity: 0.6;
      display: block;
      filter: grayscale(1);
      -webkit-filter: grayscale(1);
      height: 40px;
      width: 60px; }

main.products .row {
  margin-bottom: 0; }
main.products article:hover figure i {
  color: #9BC911; }
main.products article h2, main.products article p {
  color: #666 !important; }
main.products article p {
  margin-top: 20px; }
main.products article .btn {
  color: white;
  font-size: 1.2rem; }
main.products article h2 {
  font-size: 1.6rem;
  line-height: 100%;
  font-weight: 700;
  margin: 0;
  color: #666; }
  main.products article h2 span {
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    width: 88%;
    vertical-align: middle; }
  main.products article h2 i {
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    vertical-align: middle; }
main.products .card-action a.btn {
  display: block;
  margin: 0 auto;
  text-align: center; }
main.products figure {
  width: 100%;
  text-align: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 160px;
  position: relative;
  cursor: pointer; }
  main.products figure i {
    transition: all 600ms; }
main.products .price {
  background: #307846;
  padding: 10px;
  top: 20px;
  right: 0;
  position: absolute;
  display: none;
  color: #f6f6f6;
  border-radius: 5px 0 0 5px; }
  main.products .price i {
    color: #f6f6f6 !important; }
main.products .card-action {
  text-align: center;
  padding: 10px !important; }
main.products .card-title {
  line-height: 100%; }
main.products i {
  cursor: pointer; }
main.products .btn {
  border: .1rem solid transparent; }
  main.products .btn i {
    color: white;
    border: .1rem solid transparent; }
main.products .card-content {
  padding: 10px !important; }

main.products.categories ul.list-group {
  margin-bottom: 20px;
  margin-top: 5px; }
  main.products.categories ul.list-group > li {
    border-bottom: 1px solid #fcfcfc;
    transition: all 600ms; }
    main.products.categories ul.list-group > li > a {
      display: block;
      padding: 15px 10px;
      color: #666;
      background: white;
      transition: all 0.2s;
      font-size: 1.4rem;
      line-height: 100%;
      font-weight: 400; }
      main.products.categories ul.list-group > li > a:hover {
        background: #307846;
        color: #f6f6f6; }
        main.products.categories ul.list-group > li > a:hover::before {
          color: #f6f6f6; }
    main.products.categories ul.list-group > li > ul {
      display: none; }
  main.products.categories ul.list-group > .class_active {
    margin: 0 !important; }
    main.products.categories ul.list-group > .class_active > a {
      background: #307846;
      color: #f6f6f6; }
      main.products.categories ul.list-group > .class_active > a::before {
        color: #f6f6f6; }

main.products.categories ul.list-group {
  background: #fcfcfc; }
  main.products.categories ul.list-group > li a::before {
    font-family: fontAwesome;
    color: #666;
    margin-right: 10px;
    content: '\f054'; }
  main.products.categories ul.list-group > li a i {
    display: none; }
  main.products.categories ul.list-group > li:nth-of-type(1) a::before {
    content: '\f0ae'; }
  main.products.categories ul.list-group .ico-more {
    display: none; }

main.services article h2, main.services article p {
  color: #666 !important; }
main.services article p {
  margin-top: 20px; }
main.services article .btn {
  color: white !important;
  margin: 0; }
main.services article h2 {
  font-size: 1.8rem;
  line-height: 100%;
  font-weight: 700; }
main.services figure {
  height: 192px;
  overflow: hidden;
  background: white;
  text-align: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
main.services .card-action {
  text-align: center;
  padding: 10px !important; }
main.services .card-title {
  line-height: 100%; }
main.services i {
  cursor: pointer; }

main.services.categories ul.list-group {
  background: white; }
  main.services.categories ul.list-group > li {
    border-bottom: 1px solid #307846;
    padding: 15px 10px;
    position: relative; }
    main.services.categories ul.list-group > li::before {
      content: '';
      height: 4px;
      width: 20px;
      background: #9BC911;
      transition: all 600ms;
      display: block;
      position: absolute;
      bottom: -2px;
      left: 0%; }
    main.services.categories ul.list-group > li:hover {
      border-bottom: thin solid #666; }
    main.services.categories ul.list-group > li > a {
      display: block;
      color: #307846;
      font-size: 1.6rem;
      line-height: 100%;
      font-weight: 500; }
      main.services.categories ul.list-group > li > a:hover {
        color: #666; }
    main.services.categories ul.list-group > li > ul {
      display: none; }

.aboutUs.services figure {
  height: 100px; }
.aboutUs.services article.card {
  height: 430px; }

main.contact {
  text-align: left; }
  main.contact textarea {
    height: 80px !important; }
  main.contact .modal-close {
    float: right; }
  main.contact .message-success {
    margin: 0 20px;
    font-size: 3rem;
    line-height: 100%;
    font-weight: 100; }
  main.contact #map_wrapper, main.contact .mapping {
    height: 310px; }
  main.contact form {
    padding: 10px; }
  main.contact label.error {
    color: #FF9494;
    text-align: right;
    left: inherit;
    right: 0;
    font-size: 1rem;
    line-height: 100%;
    font-weight: 300; }
  main.contact #map_wrapper {
    border: 4px solid #fcfcfc;
    overflow: hidden; }
  main.contact .contact-data {
    margin-top: 20px;
    background: #9BC911;
    color: #f6f6f6;
    margin-bottom: 20px;
    padding: 10px 20px 10px; }
    main.contact .contact-data h3 {
      margin-bottom: 20px;
      text-align: center;
      padding-bottom: 10px;
      font-size: 1.6rem;
      line-height: 100%;
      font-weight: 400;
      text-transform: uppercase;
      border-bottom: thin solid #f6f6f6; }
    main.contact .contact-data ul {
      text-align: center; }
    main.contact .contact-data li {
      margin: 0 5px;
      margin-bottom: 10px;
      display: inline-block;
      vertical-align: top;
      white-space: normal;
      font-size: 1.4rem;
      line-height: 100%;
      font-weight: 400;
      width: auto; }
      main.contact .contact-data li i {
        color: #f6f6f6; }
  main.contact input[type="submit"] {
    background: none;
    cursor: pointer;
    border: none !important;
    color: #f6f6f6;
    letter-spacing: 0.1rem; }
  main.contact .datos_contac {
    margin-top: 20px; }

.detail {
  margin-bottom: 0; }
  .detail figure {
    overflow: hidden;
    background: white;
    text-align: center;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    float: right;
    margin: 0 0 20px 20px;
    height: 300px;
    width: 500px;
    overflow: hidden;
    text-align: center;
    border: 4px solid #fcfcfc; }
    .detail figure img, .detail figure .fancybox-thumb {
      height: 300px;
      width: 500px;
      display: block;
      position: relative; }
    .detail figure .icon {
      position: absolute;
      bottom: 15px;
      right: 15px;
      transition: all 0.2s; }
      .detail figure .icon i {
        font-size: 2.4rem;
        line-height: 100%;
        font-weight: normal;
        color: #f6f6f6; }
  .detail .price {
    display: none; }
  .detail .text {
    margin-bottom: 30px; }
  .detail .video {
    margin: 20px auto 20px;
    text-align: center; }
  .detail .actions {
    text-align: right;
    line-height: 37px;
    margin: 20px 0; }
    .detail .actions a {
      color: #f6f6f6;
      cursor: pointer;
      margin: 0 5px;
      display: inline-block;
      vertical-align: top;
      white-space: normal; }
      .detail .actions a i {
        font-size: 3; }

.myProjects {
  padding-bottom: 0 !important;
  position: relative; }
  .myProjects .slider, .myProjects .slider .slides {
    height: 75.4vh; }
    .myProjects .slider img, .myProjects .slider .slides img {
      width: 100%;
      height: 100%; }
  .myProjects .slider .indicators {
    bottom: 20px; }
  .myProjects .project-myText {
    top: 0;
    left: 0;
    position: absolute;
    width: 320px;
    background: rgba(0, 0, 0, 0.8);
    color: #f6f6f6;
    z-index: 999;
    max-height: 100%;
    padding: 20px; }
    .myProjects .project-myText .actions {
      width: 100%;
      display: block;
      margin-bottom: 20px;
      position: relative; }
    .myProjects .project-myText h1 {
      padding: 10px 0;
      margin: 0px 0px 20px !important;
      font-size: 1.6rem;
      line-height: 140%;
      font-weight: 600;
      background: none;
      color: #f6f6f6;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1), 0 -10px 0 rgba(255, 255, 255, 0.3); }
    .myProjects .project-myText .text {
      color: #f6f6f6;
      width: 100%;
      max-height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      height: 50vh; }
    .myProjects .project-myText .project-mySlider {
      width: 100%;
      position: relative;
      z-index: 9; }

@media screen and (max-width: 960px) {
  .detail .actions {
    text-align: center; } }
@media screen and (max-width: 601px) {
  .detail figure {
    max-width: 100%;
    margin: 0 0 20px;
    height: 160px;
    float: none; }
    .detail figure img, .detail figure .fancybox-thumb {
      max-width: 100%;
      height: 160px; } }

.detail figure {
  border: 6px solid #e8e8e8; }

main.news article {
  border-bottom: thin solid #dedede;
  position: relative;
  margin-bottom: 30px;
  height: 230px;
  width: 100%; }
  main.news article h2 {
    line-height: 150%; }
  main.news article figure {
    height: 200px;
    width: 350px;
    float: right;
    margin-top: 5px;
    margin-left: 20px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border: 6px solid #e8e8e8; }
  main.news article a.btn-link {
    display: inline-block;
    vertical-align: top;
    white-space: normal; }
  main.news article .icon {
    position: absolute;
    bottom: -12px;
    left: 50%;
    margin-left: -24px;
    padding: 0 10px;
    background: #fcfcfc;
    display: block;
    text-align: center; }
    main.news article .icon i {
      font-size: 2rem;
      line-height: 100%;
      font-weight: normal;
      color: #dedede; }

.noMargin {
  margin-bottom: 0; }

@media screen and (max-width: 960px) {
  main.news article {
    height: auto;
    padding-bottom: 60px; }
    main.news article figure {
      text-align: center;
      max-width: 100%;
      float: none;
      margin: 0 0 20px; } }

main.projects .row .col {
  padding: 0; }

.da-thumbs {
  position: relative; }
  .da-thumbs .myBox {
    background: #f6f6f6;
    padding: 0px;
    position: relative;
    width: 100%;
    display: block;
    height: 300px;
    margin: 0 0 0px; }
    .da-thumbs .myBox h2 {
      font-size: 1.6rem;
      line-height: 110%;
      font-weight: 700;
      padding: 10px 0;
      margin: 20px 20px 0px;
      text-transform: uppercase;
      color: #f6f6f6; }
    .da-thumbs .myBox a {
      display: block;
      position: relative;
      margin: 0; }
      .da-thumbs .myBox a figure {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        height: 300px;
        margin: 0;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat; }
      .da-thumbs .myBox a div {
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
        position: absolute;
        background: rgba(0, 0, 0, 0.8);
        width: 100%;
        height: 100%;
        margin: 0; }
        .da-thumbs .myBox a div span {
          display: block;
          padding: 10px 0;
          margin: 20px 20px 20px;
          font-size: 1.4rem;
          line-height: 140%;
          font-weight: 300;
          color: #f6f6f6;
          border-bottom: 1px solid rgba(255, 255, 255, 0.5);
          box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1), 0 -10px 0 rgba(255, 255, 255, 0.3); }

main.projects.categories ul.list-group {
  margin-bottom: 20px;
  text-align: center; }
  main.projects.categories ul.list-group > li {
    border-bottom: 1px solid white;
    padding: 15px 5px;
    transition: all 600ms;
    display: inline-block;
    vertical-align: top;
    white-space: normal; }
    main.projects.categories ul.list-group > li > a {
      display: block;
      color: #307846;
      text-transform: uppercase;
      font-size: 1.6rem;
      line-height: 100%;
      font-weight: 500; }
      main.projects.categories ul.list-group > li > a::before {
        color: #307846 !important; }
    main.projects.categories ul.list-group > li:hover {
      background: #f6f6f6; }
      main.projects.categories ul.list-group > li:hover > a {
        color: #666 !important; }
        main.projects.categories ul.list-group > li:hover > a::before {
          color: #666 !important; }
    main.projects.categories ul.list-group > li > ul {
      display: none; }
  main.projects.categories ul.list-group > .class_active {
    margin: 0 !important; }
    main.projects.categories ul.list-group > .class_active > a {
      color: #666; }
      main.projects.categories ul.list-group > .class_active > a::before {
        content: '\f0c8';
        color: #666 !important; }
main.projects.categories .text {
  margin-bottom: 20px; }
main.projects.categories .ico-more {
  display: none; }

main.projects.categories ul.list-group > li a::before {
  font-family: fontAwesome;
  color: #f6f6f6;
  margin-right: 10px;
  content: '\f096';
  transition: all 0.4s; }
main.projects.categories ul.list-group > li a:hover::before {
  content: '\f0c8'; }
main.projects.categories ul.list-group > li:nth-of-type(1) {
  display: none; }
  main.projects.categories ul.list-group > li:nth-of-type(1) a::before {
    content: '\f0ae';
    display: none; }

.myCategories {
  background: #ededed; }

main.libraries article:hover figure i {
  color: #9BC911; }
main.libraries article h2, main.libraries article p {
  color: #666 !important; }
main.libraries article p {
  margin-top: 20px; }
main.libraries article .btn {
  color: white;
  font-size: 1.2rem; }
main.libraries article h2 {
  font-size: 1.6rem;
  line-height: 100%;
  font-weight: 700; }
  main.libraries article h2 span {
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    width: 88%;
    vertical-align: middle; }
  main.libraries article h2 i {
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    vertical-align: middle; }
main.libraries figure {
  text-align: center;
  overflow: hidden;
  background: white;
  text-align: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  main.libraries figure i {
    transition: all 600ms; }
main.libraries .card-action {
  text-align: center;
  padding: 10px !important; }
main.libraries .card-title {
  line-height: 100%; }
main.libraries i {
  cursor: pointer; }
main.libraries .btn i {
  margin-left: 10px;
  color: white; }
main.libraries .card-content {
  padding: 10px !important; }

main.libraries.categories ul.list-group {
  background: white; }
  main.libraries.categories ul.list-group > li {
    border-bottom: 1px solid #307846;
    padding: 15px 10px;
    position: relative; }
    main.libraries.categories ul.list-group > li::before {
      content: '';
      height: 4px;
      width: 20px;
      transition: all 600ms;
      display: block;
      position: absolute;
      bottom: -2px;
      left: 0%; }
    main.libraries.categories ul.list-group > li:hover {
      border-bottom: thin solid #666; }
    main.libraries.categories ul.list-group > li > a {
      display: block;
      font-size: 1.6rem;
      line-height: 100%;
      font-weight: 500; }
      main.libraries.categories ul.list-group > li > a:hover {
        color: #666; }
      main.libraries.categories ul.list-group > li > a i {
        margin-right: 10px; }
    main.libraries.categories ul.list-group > li > ul {
      display: none; }

main.blog .row {
  margin-bottom: 0; }
main.blog .blog-big {
  border-right: thin solid #fcfcfc;
  padding-right: 20px; }
  main.blog .blog-big img {
    max-width: 100%;
    height: auto;
    margin: 20px auto 0;
    display: block;
    border: 4px solid #fcfcfc; }
main.blog .blog-mini {
  padding: 0px 20px 60px; }
main.blog article {
  padding-bottom: 40px;
  border-bottom: thin solid #fcfcfc;
  position: relative;
  margin-bottom: 40px;
  width: 100%; }
  main.blog article h2 {
    font-size: 2rem;
    line-height: 100%;
    font-weight: 500;
    color: #161616; }
  main.blog article .text {
    margin: 20px 0; }
  main.blog article figure {
    height: 200px;
    width: 350px;
    float: right;
    margin-left: 20px;
    overflow: hidden;
    background: white;
    text-align: center;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }
    main.blog article figure img {
      width: 100%;
      height: 100%; }
  main.blog article .icon {
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    position: absolute;
    bottom: -22px;
    left: 50%;
    margin-left: -22px;
    padding: 0 10px;
    text-align: center;
    background-size: 50%;
    display: block;
    height: 44px;
    width: 44px; }
    main.blog article .icon i {
      font-size: 2.4rem;
      line-height: 100%;
      font-weight: normal;
      color: #fcfcfc;
      margin-top: 8px; }

main.blog .row, main.blogDetail .row {
  margin-bottom: 0; }
main.blog h2, main.blogDetail h2 {
  display: block;
  font-size: 2rem;
  line-height: 100%;
  font-weight: 500;
  color: #161616;
  margin-bottom: 3px; }
main.blog article, main.blogDetail article {
  width: 100%;
  margin-bottom: 20px;
  width: 100%; }
main.blog figure, main.blogDetail figure {
  display: none; }
main.blog aside, main.blogDetail aside {
  display: block;
  width: 100%;
  background: #9BC911; }
  main.blog aside .keywords, main.blogDetail aside .keywords {
    padding: 10px; }
    main.blog aside .keywords h3, main.blogDetail aside .keywords h3 {
      font-size: 1.6rem;
      line-height: 100%;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      color: #f6f6f6;
      margin-bottom: 10px; }
    main.blog aside .keywords a, main.blogDetail aside .keywords a {
      border: .1rem solid transparent;
      display: inline-block;
      vertical-align: top;
      white-space: normal;
      vertical-align: middle;
      background: none !important;
      width: auto;
      text-decoration: underline;
      padding: 0 !important;
      margin: 3px 5px 10px;
      color: #f6f6f6; }
      main.blog aside .keywords a:nth-of-type(odd), main.blogDetail aside .keywords a:nth-of-type(odd) {
        font-size: 2rem;
        line-height: 100%;
        font-weight: normal;
        display: inline-block;
        vertical-align: top;
        white-space: normal;
        vertical-align: middle;
        margin: 0px 5px 10px; }
main.blog .date, main.blog .autor, main.blog .category, main.blogDetail .date, main.blogDetail .autor, main.blogDetail .category {
  font-size: 0;
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  vertical-align: middle;
  width: auto;
  margin-right: 5px;
  padding-right: 5px;
  border-right: thin dotted #666; }
  main.blog .date a, main.blog .autor a, main.blog .category a, main.blogDetail .date a, main.blogDetail .autor a, main.blogDetail .category a {
    color: #307846; }
  main.blog .date a, main.blog .date span, main.blog .autor a, main.blog .autor span, main.blog .category a, main.blog .category span, main.blogDetail .date a, main.blogDetail .date span, main.blogDetail .autor a, main.blogDetail .autor span, main.blogDetail .category a, main.blogDetail .category span {
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    vertical-align: middle;
    font-size: 1.3rem;
    line-height: 100%;
    font-weight: 300; }
  main.blog .date i, main.blog .autor i, main.blog .category i, main.blogDetail .date i, main.blogDetail .autor i, main.blogDetail .category i {
    font-size: 1.4rem;
    line-height: 100%;
    font-weight: normal;
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    vertical-align: middle; }
main.blog .category, main.blogDetail .category {
  border: none !important; }
main.blog .text, main.blogDetail .text {
  margin: 20px 0; }

main.blogDetail aside {
  flex: 0 120px;
  margin-left: 20px; }
  main.blogDetail aside .date, main.blogDetail aside .autor, main.blogDetail aside a {
    width: 100%;
    font-size: 1.3rem;
    line-height: 130%;
    font-weight: 400;
    display: block;
    background: #9BC911;
    color: #f6f6f6;
    text-align: center;
    padding: 10px; }
    main.blogDetail aside .date i, main.blogDetail aside .autor i, main.blogDetail aside a i {
      margin-bottom: 10px;
      display: block;
      font-size: 3rem;
      line-height: 100%;
      font-weight: normal; }
  main.blogDetail aside .date, main.blogDetail aside .autor {
    margin: 0; }
    main.blogDetail aside .date i, main.blogDetail aside .autor i {
      color: #f6f6f6; }
  main.blogDetail aside .autor {
    background: #307846; }
  main.blogDetail aside a {
    background: #666; }
    main.blogDetail aside a:hover {
      background: #161616; }
    main.blogDetail aside a i {
      margin-bottom: 10px;
      display: block;
      font-size: 3rem;
      line-height: 100%;
      font-weight: normal; }
main.blogDetail article {
  text-align: left;
  flex: 1;
  font-size: 0;
  padding-bottom: 30px; }
  main.blogDetail article .text {
    text-align: left; }
  main.blogDetail article img {
    height: 200px;
    width: 350px;
    float: right;
    margin: 0 0 20px 20px;
    overflow: hidden;
    background: white;
    text-align: center;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }
    main.blogDetail article img img {
      width: 100%;
      height: 100%; }
  main.blogDetail article .icon {
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    position: absolute;
    bottom: 50%;
    left: -12px;
    margin-left: -22px;
    background: white;
    padding: 0 10px; }
    main.blogDetail article .icon i {
      font-size: 2rem;
      line-height: 100%;
      font-weight: normal;
      color: #fcfcfc; }
main.blogDetail figure {
  display: block; }
main.blogDetail aside {
  display: block;
  flex: 0 200px; }
main.blogDetail .keywords {
  background: #9BC911;
  padding: 10px; }
  main.blogDetail .keywords h3 {
    font-size: 1.6rem;
    line-height: 100%;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    color: #f6f6f6;
    margin-bottom: 10px; }
  main.blogDetail .keywords a {
    border: .1rem solid transparent;
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    vertical-align: middle;
    background: none !important;
    width: auto;
    padding: 0 !important;
    margin: 5px 5px 10px; }
    main.blogDetail .keywords a:nth-of-type(odd) {
      font-size: 2rem;
      line-height: 100%;
      font-weight: normal;
      display: inline-block;
      vertical-align: top;
      white-space: normal;
      vertical-align: middle;
      margin: 0px 5px 10px; }

#existing-comments {
  margin: 10px 0; }

#create-comment {
  background: #fcfcfc;
  padding: 20px; }
  #create-comment h4 {
    margin: 10px 0;
    color: #111111 !important; }
  #create-comment label {
    color: #111111 !important; }

#comment.form-control {
  height: 200px;
  border: 1px solid #fcfcfc;
  padding: 10px; }
  #comment.form-control:focus {
    border: 1px solid #fcfcfc; }

#commentPost .form_submit {
  float: right;
  margin: 20px 0; }

.myComments .name, .myComments .date, .myComments .image {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  width: auto;
  vertical-align: middle;
  border: none !important;
  font-weight: 700; }
.myComments .row {
  margin-bottom: 0; }
.myComments .comment {
  border: thin solid white !important;
  background: white !important;
  padding: 10px;
  margin-bottom: 10px; }
  .myComments .comment:nth-of-type(odd) {
    background: white !important; }
.myComments .comments-profile {
  border-right: thin solid #fcfcfc; }
.myComments .comments-text {
  padding-left: 20px; }
.myComments .res_form {
  margin-top: 40px;
  width: 100%;
  position: relative;
  display: block; }
  .myComments .res_form h4 {
    margin-bottom: 20px; }

main.faq .myTabs li {
  background: #fcfcfc;
  padding: 10px;
  transition: all 0.2s;
  border-bottom: thin solid #666; }
  main.faq .myTabs li a {
    font-size: 1.4rem;
    line-height: 100%;
    font-weight: 400;
    transition: all 0.2s;
    display: block; }
    main.faq .myTabs li a i {
      margin-left: 10px; }
  main.faq .myTabs li:hover {
    background: #307846; }
    main.faq .myTabs li:hover a {
      color: #f6f6f6; }
main.faq .tab-content {
  min-height: 120px;
  padding: 0px; }
  main.faq .tab-content figure {
    width: 100%;
    height: 240px;
    overflow: hidden;
    border-radius: 10px; }
    main.faq .tab-content figure img {
      width: 100%; }
  main.faq .tab-content h2 {
    font-size: 1.5rem;
    line-height: 120%;
    font-weight: 600;
    color: #f6f6f6;
    cursor: pointer;
    background: #9BC911;
    border: thin solid #9BC911;
    border-radius: 0;
    flex: none;
    width: 100%;
    padding: 10px 20px;
    margin: 0px;
    transition: all 600ms;
    border-bottom: 1px solid white; }
    main.faq .tab-content h2:hover {
      background: #307846;
      border: thin solid #161616; }
    main.faq .tab-content h2 i {
      float: right;
      transform: rotate(90deg);
      transition: all 600ms;
      margin-top: 2px; }
  main.faq .tab-content .text {
    display: none;
    border: thin solid #fcfcfc;
    padding: 10px;
    background: white;
    transition: all 600ms; }

.accordeon-active {
  display: block !important; }

main.faq .tab-content h2.faq-active {
  background: #307846 !important;
  border: thin solid #307846 !important; }
  main.faq .tab-content h2.faq-active i {
    transform: rotate(-90deg); }

main.referrals {
  padding-bottom: 20px; }
  main.referrals .panel {
    margin-bottom: 20px; }
  main.referrals .row {
    margin-bottom: 0px; }
  main.referrals .btn {
    margin-top: 10px; }
  main.referrals .bx-pager.bx-default-pager {
    display: none; }
  main.referrals .carousel {
    padding: 20px !important; }
    main.referrals .carousel .container {
      width: 100% !important; }
    main.referrals .carousel .text {
      font-size: 1.6rem;
      line-height: 150%;
      font-weight: 100;
      font-style: italic; }
    main.referrals .carousel h3 {
      padding: 5px;
      display: block; }
    main.referrals .carousel i {
      font-size: 5rem !important; }

